import './trusted.scss';
import Swiper from '../../vendor/swiper/swiper';

class Trusted {
    constructor ($element) {
        // trusted slider
        this.$trusted = $element;
        this.$slider = this.$trusted.querySelectorAll('[data-trusted-slider="slider"]');
        this.$slides = this.$trusted.querySelectorAll('[data-trusted-slider="slide"]');
        this.centered = true;

        this.slider = null;
    }

    initialize () {
        this.checkSize();
    }

    checkSize () {
        if (window.innerWidth < 1024 && this.$slides.length > 2) {
            if (this.slider === null) {
                this.initSlider();
            }
        }

        window.resizeHandler.customFunctions.push(() => {
            if (window.innerWidth < 1024 && this.$slides.length > 2) {
                if (this.slider === null) {
                    this.initSlider();
                }
            } else {
                this.destroySlider();
            }
        });
    }

    initSlider () {
        this.slider = new Swiper(this.$slider, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            breakpointsInverse: true,
            pagination: {
                el: '.trusted-pagination',
                clickable: true
            },
            breakpoints: {
                480: {
                    spaceBetween: 46
                }
            }
        });
    }

    destroySlider () {
        if (this.slider !== null) {
            this.slider.destroy(true, true);

            for (let i = 0; i < this.$slides.length; i++) {
                this.$slides.style = '';
            }

            this.slider = null;
        }
    }
}

export { Trusted };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $trusted = $context.querySelector('[data-trusted-slider="root"]');

    if ($trusted) {
        const $newTrusted = new Trusted($trusted);
        $newTrusted.initialize();
    }
});
